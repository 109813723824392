import Vue from 'vue'
// Import Framework7
import Framework7 from './index'

// Import Framework7-Vue Plugin
import Framework7Vue from './vue';

// Import Icons and App Custom Styles
import '../../css/framework7.less';
import '../../css/app.scss';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue, {
  theme: 'md',
  Vue,
});